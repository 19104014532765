import axios from "axios";
import TaxRate from "@/model/TaxRate";
export default class TaxRateService {
    loadAllTaxRates() {
        return axios.get('taxrates', {
            withCredentials: true
        }).then(response => {
            const taxrates = [];
            for (const entry of response.data) {
                const taxrate = new TaxRate();
                taxrate.init(entry);
                taxrates.push(taxrate);
            }
            taxrates.sort((a, b) => a.id - b.id);
            return taxrates;
        });
    }
    saveTaxRate(taxRate) {
        return axios.post('taxrate', taxRate, {
            withCredentials: true
        }).then(response => {
            const taxrate = new TaxRate();
            taxrate.init(response.data);
            return taxrate;
        });
    }
    deleteTaxRate(taxRate) {
        return axios.delete('taxrate/' + taxRate.id, {
            withCredentials: true
        });
    }
}
