class Formatter {
    static currencyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
    });
    static currency(input) {
        return this.currencyFormatter.format(input);
    }
    static date(input) {
        return input ? input.toLocaleDateString('de-DE', { day: "2-digit", month: "2-digit", year: "numeric" }) : "";
    }
    static dateTime(input) {
        return input ? this.date(input) + " " + input.toLocaleTimeString() : "";
    }
    static isoDate(input) {
        return input ? input.toISOString().split('T')[0] : null;
    }
    static contractNumber(order) {
        return (order.contract !== undefined && order.contract !== null) ?
            `${order.contract.ledger?.invoicePrefix}${order.contract.contractYear}-${order.contract?.contractNumber?.toString().padStart(4, '0')}` :
            "";
    }
    static decimalToPercent(input) {
        return (input * 100).toFixed(2) + "%";
    }
}
export { Formatter };
