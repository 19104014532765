import axios from "axios";
export default class AdminService {
    deleteLedgerOrders(ledgerId) {
        return axios.delete("/admin/order", {
            params: {
                ledgerId: ledgerId
            },
            withCredentials: true
        });
    }
    downloadDatevExport(ledgerId, dateFrom, dateTo, businessYearStart) {
        return axios.get("/admin/datev/export", {
            params: {
                ledgerId: ledgerId,
                from: dateFrom,
                to: dateTo,
                businessYearStart: businessYearStart
            },
            responseType: 'arraybuffer',
            withCredentials: true,
            handledHttpErrorStatus: [500]
        });
    }
}
