export default class TaxRate {
    id;
    rate;
    accountNumber = "";
    init(other) {
        this.id = other.id;
        this.rate = other.rate;
        this.accountNumber = other.accountNumber;
    }
}
