import { defineStore } from "pinia";
import Setting from "@/model/Setting";
import axios from "axios";
import { SettingCategory } from "@/model/enum/SettingCategory";
export const useSettingsStore = defineStore("SettingsStore", {
    state: () => {
        return {
            settings: new Map(),
            defaultSettings: [
                { key: "store_name", category: SettingCategory.GENERAL, isPublic: true, value: 'Krumos Booking' },
                { key: "tac_link", category: SettingCategory.GENERAL, isPublic: true },
                { key: "data_protection_link", category: SettingCategory.GENERAL, isPublic: true },
                { key: "revocation_link", category: SettingCategory.GENERAL, isPublic: true },
                { key: "booking_complete_redirect_link", category: SettingCategory.GENERAL, isPublic: true },
                { key: "company_name", category: SettingCategory.GENERAL, isPublic: false },
                { key: "company_ceo", category: SettingCategory.GENERAL, isPublic: false },
                { key: "company_street", category: SettingCategory.GENERAL, isPublic: false },
                { key: "company_city", category: SettingCategory.GENERAL, isPublic: false },
                { key: "company_phone", category: SettingCategory.GENERAL, isPublic: false },
                { key: "company_website", category: SettingCategory.GENERAL, isPublic: false },
                { key: "company_email", category: SettingCategory.GENERAL, isPublic: false },
                { key: "company_tax_id", category: SettingCategory.GENERAL, isPublic: false },
                { key: "company_logo", category: SettingCategory.GENERAL, isPublic: false },
                { key: "send_order_received_mail", category: SettingCategory.EMAIL, isPublic: false, value: 'false' },
                { key: "order_received_mail_content_start", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                { key: "order_received_mail_content_end", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                {
                    key: "order_received_mail_attachements",
                    category: SettingCategory.EMAIL,
                    isPublic: false,
                    value: '[]'
                },
                { key: "send_order_accepted_mail", category: SettingCategory.EMAIL, isPublic: false, value: 'false' },
                { key: "order_accepted_mail_content_start", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                { key: "order_accepted_mail_content_end", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                {
                    key: "order_accepted_mail_attachements",
                    category: SettingCategory.EMAIL,
                    isPublic: false,
                    value: '[]'
                },
                { key: "send_order_declined_mail", category: SettingCategory.EMAIL, isPublic: false, value: 'false' },
                { key: "order_declined_mail_content_start", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                { key: "order_declined_mail_content_end", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                {
                    key: "order_declined_mail_attachements",
                    category: SettingCategory.EMAIL,
                    isPublic: false,
                    value: '[]'
                },
                { key: "send_order_changed_mail", category: SettingCategory.EMAIL, isPublic: false, value: 'false' },
                { key: "order_changed_mail_content_start", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                { key: "order_changed_mail_content_end", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                { key: "order_changed_mail_attachements", category: SettingCategory.EMAIL, isPublic: false, value: '[]' },
                { key: "send_order_canceled_mail", category: SettingCategory.EMAIL, isPublic: false, value: 'false' },
                { key: "order_canceled_mail_content_start", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                { key: "order_canceled_mail_content_end", category: SettingCategory.EMAIL, isPublic: false, value: '' },
                {
                    key: "order_canceled_mail_attachements",
                    category: SettingCategory.EMAIL,
                    isPublic: false,
                    value: '[]'
                },
                { key: "invoice_info_text", category: SettingCategory.INVOICE, isPublic: false, value: '' },
                { key: "datev_advisor_number", category: SettingCategory.TAX, isPublic: false, value: '' },
                { key: "datev_client_number", category: SettingCategory.TAX, isPublic: false, value: '' },
                { key: "datev_corresponding_account", category: SettingCategory.TAX, isPublic: false, value: '' },
            ],
            loaded: false,
            loadedPrivate: false,
        };
    },
    actions: {
        async loadSettings() {
            this.defaultSettings.forEach(defaultSetting => {
                if (!this.settings.has(defaultSetting.key) && defaultSetting.isPublic) {
                    const setting = new Setting(defaultSetting.key, defaultSetting.category, defaultSetting.isPublic);
                    if (defaultSetting.value) {
                        setting.value = defaultSetting.value;
                    }
                    this.settings.set(defaultSetting.key, setting);
                }
            });
            await axios.get("settings").then((response) => {
                response.data.forEach(setting => {
                    this.settings.set(setting.key, setting);
                });
            }).then(() => {
                this.loaded = true;
            });
        },
        async loadPrivateSettings() {
            await axios.get("settings/private", {
                withCredentials: true
            }).then((response) => {
                response.data.forEach(setting => {
                    this.settings.set(setting.key, setting);
                });
                this.defaultSettings.forEach(defaultSetting => {
                    if (!this.settings.has(defaultSetting.key) && !defaultSetting.isPublic) {
                        const setting = new Setting(defaultSetting.key, defaultSetting.category, defaultSetting.isPublic);
                        if (defaultSetting.value) {
                            setting.value = defaultSetting.value;
                        }
                        this.settings.set(defaultSetting.key, setting);
                    }
                });
                this.loadedPrivate = true;
            });
        },
        upload(key) {
            const setting = this.settings.get(key);
            return axios.post("/setting", setting, {
                withCredentials: true
            });
        }
    }
});
