import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProductView from "@/views/ProductView.vue";
import CartView from "@/views/CartView.vue";
import CheckoutView from "@/views/CheckoutView.vue";
import LoginView from "@/views/LoginView.vue";
import { useUserStore } from "@/stores/UserStore";
import AdminProductListView from "@/views/admin/AdminProductListView.vue";
import EditProductView from "@/views/admin/EditProductView.vue";
import OrderReceivedView from "@/views/OrderReceivedView.vue";
import AdminResourceListView from "@/views/admin/AdminResourceListView.vue";
import EditResourceView from "@/views/admin/EditResourceView.vue";
import AdminResourceGroupListView from "@/views/admin/AdminResourceGroupListView.vue";
import EditResourceGroupView from "@/views/admin/EditResourceGroupView.vue";
import ResourceBookingsView from "@/views/admin/ResourceBookingsView.vue";
import AvailabilityListView from "@/views/admin/AvailabilityListView.vue";
import OrderListView from "@/views/admin/OrderListView.vue";
import EditOrderView from "@/views/admin/EditOrderView.vue";
import CustomerListView from "@/views/admin/CustomerListView.vue";
import EditCustomerView from "@/views/admin/EditCustomerView.vue";
import DashboardView from "@/views/admin/DashboardView.vue";
import LocationListView from "@/views/admin/LocationListView.vue";
import EditLocationView from "@/views/admin/EditLocationView.vue";
import NewOrderView from "@/views/admin/NewOrderView.vue";
import GeneralSettingsView from "@/views/admin/GeneralSettingsView.vue";
import EmailSettingsView from "@/views/admin/EmailSettingsView.vue";
import InvoiceSettingsView from "@/views/admin/InvoiceSettingsView.vue";
import DataSettingsView from "@/views/admin/DataSettingsView.vue";
import { Authorities } from "@/model/User.model";
import TaxSettingsView from "@/views/admin/TaxSettingsView.vue";
const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            breadCrumb() {
                return [
                    { title: 'Shop' }
                ];
            },
            allowAnonymous: true
        }
    },
    {
        path: '/product/:id',
        name: 'product',
        component: ProductView,
        props: true,
        meta: {
            breadCrumb(currentPage) {
                return [
                    {
                        title: 'Shop',
                        to: { name: 'home' }
                    },
                    {
                        title: currentPage,
                        to: {}
                    }
                ];
            },
            allowAnonymous: true
        }
    },
    {
        path: '/cart',
        name: "cart",
        component: CartView,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: '/checkout',
        name: "checkout",
        component: CheckoutView,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: '/orderReceived',
        name: "orderReceived",
        component: OrderReceivedView,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: '/login',
        name: "login",
        component: LoginView,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: '/admin/products',
        name: "admin-products",
        component: AdminProductListView,
    },
    {
        path: '/admin/products/new',
        name: "admin-products-new",
        component: EditProductView,
    },
    {
        path: '/admin/products/:id',
        name: "admin-products-edit",
        component: EditProductView,
        props: true
    },
    {
        path: '/admin/resources/',
        name: "admin-resources",
        component: AdminResourceListView,
    },
    {
        path: '/admin/resources/new',
        name: "admin-resources-new",
        component: EditResourceView,
    },
    {
        path: '/admin/resources/:id',
        name: "admin-resources-edit",
        component: EditResourceView,
        props: (route) => {
            const id = BigInt(route.params.id);
            return { id };
        }
    },
    {
        path: '/admin/resourceGroups/',
        name: "admin-resource-groups",
        component: AdminResourceGroupListView,
    },
    {
        path: '/admin/resourceGroups/new',
        name: "admin-resource-groups-new",
        component: EditResourceGroupView,
    },
    {
        path: '/admin/resourceGroups/:id',
        name: "admin-resource-groups-edit",
        component: EditResourceGroupView,
        props: (route) => {
            const id = BigInt(route.params.id);
            return { id };
        }
    },
    {
        path: '/admin/resourceBookings',
        name: "admin-resource-bookings",
        component: ResourceBookingsView,
    },
    {
        path: '/admin/availabilities',
        name: "admin-availabilities",
        component: AvailabilityListView,
    },
    {
        path: '/admin/orders',
        name: "admin-orders",
        component: OrderListView,
    },
    {
        path: '/admin/orders/:id',
        name: "admin-orders-edit",
        component: EditOrderView,
        props: true
    },
    {
        path: '/admin/orders/new',
        name: "admin-orders-new",
        component: NewOrderView,
    },
    {
        path: '/admin/customers',
        name: "admin-customers",
        component: CustomerListView,
    },
    {
        path: '/admin/customers/new',
        name: "admin-customers-new",
        component: EditCustomerView,
    },
    {
        path: '/admin/customers/:id',
        name: "admin-customers-edit",
        component: EditCustomerView,
        props: true
    },
    {
        path: '/admin/dashboard',
        name: "admin-dashboard",
        component: DashboardView,
    },
    {
        path: '/admin/locations',
        name: "admin-locations",
        component: LocationListView,
    },
    {
        path: '/admin/locations/new',
        name: "admin-locations-new",
        component: EditLocationView,
    },
    {
        path: '/admin/locations/:id',
        name: "admin-locations-edit",
        component: EditLocationView,
        props: true
    },
    {
        path: '/admin/settings/general',
        name: "admin-settings-general",
        component: GeneralSettingsView,
    },
    {
        path: '/admin/settings/email',
        name: "admin-settings-email",
        component: EmailSettingsView,
    },
    {
        path: '/admin/settings/invoice',
        name: "admin-settings-invoice",
        component: InvoiceSettingsView,
    },
    {
        path: '/admin/settings/data',
        name: "admin-settings-data",
        component: DataSettingsView,
        meta: {
            authority: Authorities.ROLE_OWNER
        }
    },
    {
        path: '/admin/settings/tax',
        name: "admin-settings-tax",
        component: TaxSettingsView
    },
];
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    const authRequired = !to.meta.allowAnonymous;
    const authorized = !!useUserStore().user.token;
    const necessaryAuthority = to.meta.authority;
    if ((authRequired && !authorized) || (necessaryAuthority != null && !(useUserStore().hasAuthority(necessaryAuthority)))) {
        next('/login');
    }
    else {
        next();
    }
});
export default router;
